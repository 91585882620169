import { useEffect, useState } from "react";
import { deleteApps } from "../../api/app/delete";
import { getAppList, TAKE } from "../../api/app/list";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { App, AppType } from "../../common/types";
import { b2h } from "../../common/util";
import Loader from "../../components/Loader/Loader";
import { FRONTEND } from "../../constant";
import strings from "../../constant/strings";
import {
  removeApp,
  selectAppList,
  selectAppListLoaded,
  selectAppListSkip,
  selectAppListTotalCount,
  setAppListError,
  setAppListLoaded,
  setAppListLoading,
  setAppListSkip,
  setApps,
  toggleApp,
} from "./AppListSlice";

const AppList = () => {
  const appList = useAppSelector(selectAppList);
  const totalCount = useAppSelector(selectAppListTotalCount);
  const loaded = useAppSelector(selectAppListLoaded);
  const skip = useAppSelector(selectAppListSkip);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const abort = new AbortController();
    getAppList(abort.signal, skip.skip)
      .then((value) => {
        if (!value || !value.data || !value.data.length) {
          dispatch(setAppListLoading(false));
          return;
        }
        const newList = value.data.map((newApp: any) => {
          return {
            id: newApp.id,
            shortId: newApp.shortId,
            name: newApp.name,
            date: newApp.date,
            type: newApp.type,
            identifier: newApp.identifier,
            version: newApp.version,
            versionCode: newApp.versionCode,
            size: newApp.size,
            appUrl: newApp.appUrl,
            iconUrl: newApp.iconUrl,
            selected: false,
          } as App;
        }) as App[];
        dispatch(setApps(newList));
        dispatch(setAppListLoaded(skip.skip + newList.length));
        dispatch(setAppListError(""));
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(setAppListError(error.response.data.message));
        } else {
          dispatch(setAppListError(strings._unknownError));
        }
      })
      .finally(() => {
        dispatch(setAppListLoading(false));
      });
    return () => {
      abort.abort();
    };
  }, [dispatch, skip]);

  const [pages, setPages] = useState<{ number: number; skip: number }[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(0);

  const LIMIT = TAKE;
  useEffect(() => {
    setTotalPages(
      Math.floor(totalCount / LIMIT) + (totalCount % LIMIT > 0 ? 1 : 0)
    );
  }, [LIMIT, totalCount]);

  useEffect(() => {
    setCurrentPage(Math.floor(skip.skip / LIMIT) + 1);
    /* let startPage = currentPage - 2;
    let endPage = currentPage + 2;
    if (startPage < 1) {
      startPage = 1;
    }
    if (endPage > totalPages) {
      endPage = totalPages;
    }
    if (endPage - startPage + 1 < LIMIT && totalPages >= LIMIT) {
      if (startPage > 1) {
        startPage -= LIMIT - (endPage - startPage + 1);
        if (startPage < 1) {
          startPage = 1;
        }
      }
    }
    if (endPage - startPage + 1 < LIMIT && totalPages >= LIMIT) {
      if (endPage < totalPages) {
        endPage += LIMIT - (endPage - startPage + 1);
        if (endPage > totalPages) {
          endPage = totalPages;
        }
      }
    } */
    let startPage = 1; // display all pages instead of TAKE limit
    // this makes the above code redundant
    let endPage = totalPages;
    const newPages: typeof pages = [];
    for (let i = startPage; i <= endPage; i++) {
      newPages.push({
        number: i,
        skip: (i - 1) * LIMIT,
      });
    }
    setPages(newPages);
  }, [LIMIT, currentPage, skip, totalCount, totalPages]);

  return appList.loading ? (
    <Loader />
  ) : appList.error ? (
    <div>{appList.error}</div>
  ) : (
    <div className="container">
      <div className="text-end">Total count: {totalCount}</div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <thead className="table-dark">
            <tr>
              <th>No.</th>
              <th>Icon</th>
              <th>Name</th>
              <th>Date</th>
              <th>OS</th>
              <th>Identifier</th>
              <th>Version</th>
              <th>VersionCode</th>
              <th>Size</th>
              <th>Link</th>
              <th></th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            {appList.apps.map((app, index) => {
              return (
                <tr
                  className={`${app.selected ? "table-danger" : ""}`}
                  key={app.id}
                >
                  <td className="text-end">
                    {totalCount - (currentPage * TAKE - TAKE + (index + 1)) + 1}
                  </td>
                  <td className="text-center">
                    <img
                      src={app.iconUrl}
                      height={34}
                      width={34}
                      alt="App icon"
                    />
                  </td>
                  <td>{app.name}</td>
                  <td>{new Date(app.date).toLocaleString()}</td>
                  <td>
                    <img
                      src={
                        app.type === AppType.Android.toString()
                          ? "/android.svg"
                          : "/ios.svg"
                      }
                      height={34}
                      width={34}
                      alt="App icon"
                    />
                    &nbsp;
                    {app.type === AppType.Android.toString()
                      ? AppType.Android.toString()
                      : AppType.IOS.toString().toUpperCase()}
                  </td>
                  <td>{app.identifier}</td>
                  <td>{app.version}</td>
                  <td>{app.versionCode}</td>
                  <td>{b2h(app.size || 1)}</td>
                  <td>
                    <a
                      href={`${FRONTEND}/${app.shortId}`}
                    >{`${FRONTEND}/${app.shortId}`}</a>
                  </td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `${FRONTEND}/${app.shortId}`
                        )
                      }
                    >
                      Copy
                    </button>
                  </td>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      defaultChecked={app.selected}
                      onInput={(e: any) => {
                        dispatch(toggleApp(app.id));
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {appList.apps.some((app) => app.selected) && (
        <div className="text-end mb-3">
          <button
            className="btn btn-danger"
            onClick={async () => {
              const apps = appList.apps
                .filter((app) => app.selected)
                .map((app) => app.id);
              try {
                const res = await deleteApps(apps);
                if (res.status === 200) {
                  dispatch(removeApp(apps));
                }
              } catch (err) {}
            }}
          >
            Delete
          </button>
        </div>
      )}
      <div className="mb-3">
        <div className="text-center overflow-auto">
          <ul className="pagination d-inline-flex mt-1">
            <li className={`page-item`}>
              <button
                className="page-link"
                onClick={() => {
                  let newSkip = loaded - appList.apps.length - TAKE;
                  if (newSkip < 0) {
                    newSkip = 0;
                  }
                  dispatch(setAppListSkip(newSkip));
                }}
              >
                Previous
              </button>
            </li>
            {pages.map((page, index) => {
              return (
                <li
                  className={`page-item ${
                    page.number === currentPage && "active"
                  }`}
                  key={`pageButton${index}`}
                >
                  <button
                    className="page-link"
                    onClick={() => {
                      dispatch(setAppListSkip(page.skip));
                    }}
                  >
                    {page.number}
                  </button>
                </li>
              );
            })}
            <li className={`page-item`}>
              <button
                className="page-link"
                onClick={() => {
                  if (currentPage !== totalPages) {
                    dispatch(setAppListSkip(loaded));
                  }
                }}
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AppList;
