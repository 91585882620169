import { Link } from "react-router-dom";

export type NavItem = {
  key: number;
  name: string;
  href: string;
  active: boolean;
};

const Navbar = ({
  items,
  children,
}: {
  items?: NavItem[];
  children?: JSX.Element | JSX.Element[];
}) => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-2">
              {items?.map((item) => (
                <li className="nav-item" key={item.key}>
                  <Link
                    to={item.href}
                    className={`nav-link ${item.active ? "active" : ""}`}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
            {children}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
