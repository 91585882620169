import { useParams } from "react-router-dom";
import Header from "../header/Header";
import Index from "../index/Index";
import QRCode from "react-qr-code";
import { FRONTEND } from "../../constant";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectShortId,
  setApp,
  setShortIdError,
  setShortIdLoading,
} from "./ShortIdSlice";
import { useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { getShortApp } from "../../api/shortApp/shortApp";
import strings from "../../constant/strings";
import { AppType } from "../../common/types";
import { b2h } from "../../common/util";
import styles from "./ShortId.module.css";

const ShortId = () => {
  const NavBar = localStorage.loggedIn ? Header : Index;
  const params = useParams();
  const id: string | undefined = params.id!!;

  const shortId = useAppSelector(selectShortId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const abort = new AbortController();
    getShortApp(abort.signal, id)
      .then((value) => {
        if (!value.data || !value.data.id) {
          return;
        }
        dispatch(
          setApp({
            id: value.data.id,
            shortId: value.data.shortId,
            name: value.data.name,
            date: value.data.date,
            type: value.data.type,
            identifier: value.data.identifier,
            version: value.data.version,
            versionCode: value.data.versionCode,
            size: value.data.size,
            appUrl: value.data.appUrl,
            iconUrl: value.data.iconUrl,
            selected: false,
          })
        );
        dispatch(setShortIdError(""));
        dispatch(setShortIdLoading(false));
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(setShortIdError(error.response.data.message));
        } else {
          dispatch(setShortIdError(strings._unknownError));
        }
      })
      .finally(() => {
        dispatch(setShortIdLoading(false));
      });
    return () => {
      abort.abort();
    };
  }, [dispatch, id]);

  return (
    <NavBar>
      <div className="text-center">
        {shortId.loading ? (
          <Loader />
        ) : shortId.error ? (
          <div className="text-danger">{shortId.error}</div>
        ) : (
          <div>
            <div
              className="card d-inline-block mt-5 mx-2"
              style={{ maxWidth: "100%", width: "300px" }}
            >
              <div
                className="card-header text-start d-flex flex-row align-items-center px-2"
                style={{ overflow: "hidden" }}
              >
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={shortId.app?.iconUrl}
                    height={34}
                    width={34}
                    alt="App icon"
                  />
                </div>
                <h4 className="card-title flex-grow-1 mb-0 ms-2">
                  {shortId.app?.name}
                </h4>
                <img
                  src={
                    shortId.app?.type === AppType.Android.toString()
                      ? "/android.svg"
                      : "/ios.svg"
                  }
                  height={34}
                  width={34}
                  alt="App icon"
                />
              </div>
              <div className="card-body">
                <QRCode value={FRONTEND + "/" + id} size={200} />
              </div>
              <div className="card-footer">
                <a
                  href={
                    shortId.app?.type === AppType.Android.toString()
                      ? shortId.app.appUrl
                      : "itms-services://?action=download-manifest&url=" +
                        shortId.app?.appUrl
                  }
                  className="btn btn-primary"
                >
                  Install
                </a>
              </div>
            </div>
            <div className="mt-5">
              <div
                className={
                  "table-responsive  mx-auto " + styles.applistContainer
                }
              >
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th scope="row">{strings.name}</th>
                      <td>{shortId.app?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">{strings.link}</th>
                      <td>
                        <a href={`${FRONTEND}/${id}`}>{`${FRONTEND}/${id}`}</a>
                        &nbsp;
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            navigator.clipboard.writeText(`${FRONTEND}/${id}`)
                          }
                        >
                          {strings.copy}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{strings.date}</th>
                      <td>
                        {shortId.app?.date
                          ? new Date(shortId.app?.date).toLocaleString()
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{strings.os}</th>
                      <td>
                        <img
                          src={
                            shortId.app?.type === AppType.Android.toString()
                              ? "/android.svg"
                              : "/ios.svg"
                          }
                          height={34}
                          width={34}
                          alt="App icon"
                        />
                        &nbsp;
                        {shortId.app?.type === AppType.Android.toString()
                          ? AppType.Android.toString()
                          : AppType.IOS.toString().toUpperCase()}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{strings.icon}</th>
                      <td>
                        <img
                          src={shortId.app?.iconUrl}
                          height={34}
                          width={34}
                          alt="App icon"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{strings.package_identifier}</th>
                      <td>{shortId.app?.identifier}</td>
                    </tr>
                    <tr>
                      <th scope="row">{strings.version}</th>
                      <td>{shortId.app?.version}</td>
                    </tr>
                    <tr>
                      <th scope="row">{strings.versionCode}</th>
                      <td>{shortId.app?.versionCode}</td>
                    </tr>
                    <tr>
                      <th scope="row">{strings.size}</th>
                      <td>{b2h(shortId.app?.size || 1)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </NavBar>
  );
};

export default ShortId;
