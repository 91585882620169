import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { App } from "../../common/types";


export interface AppListState {
  totalCount: number;
  apps: App[];
  loading: boolean;
  error: string;
  loaded: number;
  skip: {skip: number, time: number};
}

export const initialState: AppListState = {
  totalCount: 0,
  apps: [],
  loading: true,
  error: "",
  loaded: 0,
  skip: {skip: 0, time: new Date().getTime()},
};

export const AppListSlice = createSlice({
  name: "appList",
  initialState,
  reducers: {
    setAppListTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
    setAppListLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAppListError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetAppList: (state) => {
      Object.assign(state, initialState);
    },
    setApps: (state, action: PayloadAction<App[]>) => {
      state.apps = action.payload;
    },
    toggleApp: (state, action: PayloadAction<string>) => {
      for (const app of state.apps) {
        if (app.id === action.payload) {
          app.selected = !app.selected;
          break;
        }
      }
    },
    setAppListLoaded: (state, action: PayloadAction<number>) => {
      state.loaded = action.payload;
    },
    setAppListSkip: (state, action: PayloadAction<number>) => {
      state.skip = {skip: action.payload, time: new Date().getTime()};
    },
    removeApp: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((id) => {
        for (let i = 0; i < state.apps.length; i++) {
          if (state.apps[i].id === id) {
            state.apps.splice(i, 1);
            break;
          }
        }
      });
    },
  },
});

export const {
  setAppListLoading,
  setAppListError,
  resetAppList,
  setApps,
  setAppListLoaded,
  toggleApp,
  setAppListSkip,
  removeApp,
  setAppListTotalCount,
} = AppListSlice.actions;

export const selectAppListTotalCount = (state: RootState) => state.appList.totalCount;
export const selectAppList = (state: RootState) => state.appList;
export const selectAppListLoading = (state: RootState) =>
  state.appList.loading;
export const selectAppListError = (state: RootState) => state.appList.error;
export const selectAppListLoaded = (state: RootState) => state.appList.loaded;
export const selectAppListSkip = (state: RootState) => state.appList.skip;

export default AppListSlice.reducer;
