/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { logout } from "../../api/account/logout";
import { getProfile } from "../../api/profile/get";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Loader from "../../components/Loader/Loader";
import Navbar from "../../components/Navbar/Navbar";
import strings from "../../constant/strings";
import {
  selectHeaderName,
  selectHeaderEmail,
  setHeaderLoading,
  setHeaderEmail,
  setHeaderError,
  setHeaderName,
  selectHeaderLoading,
} from "./HeaderSlice";

const Header = ({children}: {children?: JSX.Element[] | JSX.Element}) => {
  const name = useAppSelector(selectHeaderName);
  const email = useAppSelector(selectHeaderEmail);
  const loading = useAppSelector(selectHeaderLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const abort = new AbortController();
    getProfile(abort.signal)
      .then((value) => {
        if (!value.data || !value.data.email) {
          return;
        }
        dispatch(setHeaderEmail(value.data.email));
        dispatch(setHeaderName(value.data.name ? value.data.name : ""));
        dispatch(setHeaderError(""));
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(setHeaderError(error.response.data.message));
        } else {
          dispatch(setHeaderError(strings._unknownError));
        }
      })
      .finally(() => {
        dispatch(setHeaderLoading(false));
      });
    return () => {
      abort.abort();
    };
  }, [dispatch]);
  return (
    <div>
      <Navbar>
        <a className="navbar-brand" href="/">
          {strings._appName}
        </a>
        {loading ? (
          <ul>
            <li>
              <Loader />
            </li>
          </ul>
        ) : (
          <ul className="navbar-nav ms-auto me-2">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {name || email}
              </a>
              <div
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <a className="dropdown-item" href="#">
                  {strings.profile}
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item text-danger"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    localStorage.loggedIn = false;
                    dispatch(setHeaderLoading(true));
                    logout().finally(() => {
                      document.location.href = "/";
                    });
                  }}
                >
                  {strings.logout}
                </a>
              </div>
            </li>
          </ul>
        )}
      </Navbar>
      {children}
    </div>
  );
};

export default Header;
