import axios from "axios";
import { DOMAIN } from "../../constant";

export const login = (email: string, password: string) => {
  return axios.post(
    `${DOMAIN}/account/login`,
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  );
};
