import { useState } from "react";
import { _uniqueId } from "../../common/util";

const TextInput = ({
  label,
  placeholder,
  type = "text",
  error,
  hint,
  value,
  valueSetter,
  required,
}: {
  label: string;
  placeholder: string;
  type?: string;
  error?: string;
  hint?: string;
  value?: string;
  required?: boolean;
  valueSetter?: (value: string) => void;
}) => {
  const [inputId] = useState(() => _uniqueId());
  return (
    <div className="form-group">
      <label htmlFor={inputId}>{label}</label>
      <input
        type={type}
        className="form-control"
        id={inputId}
        value={value}
        aria-describedby={`${inputId}Help`}
        aria-errormessage={`${inputId}Error`}
        placeholder={placeholder}
        required={required}
        onInput={(event) => {
          valueSetter && valueSetter((event.target as HTMLInputElement).value);
        }}
      />
      {error && (
        <div>
          <small id={`${inputId}Error`} className="form-text text-danger">
            {error}
          </small>
        </div>
      )}
      {hint && (
        <div>
          <small id={`${inputId}Help`} className="form-text text-muted">
            {hint}
          </small>
        </div>
      )}
    </div>
  );
};

export default TextInput;
