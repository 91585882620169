import { login } from "../../api/account/login";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Index from "../index/Index";
import AccountForm from "../accountForm/AccountForm";
import { selectForm, setAccountFormError, setAccountFormLoading } from "../accountForm/AccountFormSlice";

const Login = () => {
  const form = useAppSelector(selectForm);
  const dispatch = useAppDispatch();
  const loginNow = async (email: string, password: string) => {
    dispatch(setAccountFormLoading(true));
    try {
      await login(email, password);
      localStorage.loggedIn = "true";
      window.location.href = '/';
    } catch (error) {
      if (
        (error as any).response &&
        (error as any).response.data &&
        (error as any).response.data.message
      ) {
        dispatch(setAccountFormError((error as any).response.data.message));
      }
    } finally {
      dispatch(setAccountFormLoading(false));
    }
  };

  return (
    <Index>
      <AccountForm type="login" onSubmit={() => loginNow(form.email, form.password)} />
    </Index>
  );
};

export default Login;
