import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface HeaderState {
  email: string;
  name: string;
  loading: boolean;
  error: string;
}

export const initialState: HeaderState = {
  email: "",
  name: "",
  loading: true,
  error: "",
};

export const HeaderSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeaderEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setHeaderName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
      state.error = "";
    },
    setHeaderLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setHeaderError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetHeaderForm: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setHeaderEmail,
  setHeaderName,
  setHeaderLoading,
  setHeaderError,
  resetHeaderForm,
} = HeaderSlice.actions;

export const selectHeader = (state: RootState) => state.header;
export const selectHeaderLoading = (state: RootState) =>
  state.header.loading;
export const selectHeaderError = (state: RootState) => state.header.error;
export const selectHeaderName = (state: RootState) => state.header.name;
export const selectHeaderEmail = (state: RootState) => state.header.email;

export default HeaderSlice.reducer;
