import authAxios from "../../common/authAxios";
import { DOMAIN } from "../../constant";

export const deleteApps = (ids: string[]) => {
  return authAxios.delete(`${DOMAIN}/app/delete`, {
    withCredentials: true,
    data: ids.map((id) => `apps[]=${id}`).join("&"),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
