import { register } from "../../api/account/register";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Index from "../index/Index"
import AccountForm from "../accountForm/AccountForm";
import { selectForm, setAccountFormError, setAccountFormLoading, setAccountFormSuccess } from "../accountForm/AccountFormSlice";

const Register = () => {
  const form = useAppSelector(selectForm);
  const dispatch = useAppDispatch();

  const registerNow = async (email: string, name: string, password: string) => {
    dispatch(setAccountFormLoading(true));
    try {
      await register(email, name, password);
      dispatch(setAccountFormSuccess());
    } catch (error) {
      if (
        (error as any).response &&
        (error as any).response.data &&
        (error as any).response.data.message
      ) {
        dispatch(setAccountFormError((error as any).response.data.message));
      }
    } finally {
      dispatch(setAccountFormLoading(false));
    }
  };
  return (
    <Index>
      <AccountForm
        type="register"
        onSubmit={() => {
          registerNow(form.email, form.name, form.password);
        }}
      />
    </Index>
  );
};

export default Register;
