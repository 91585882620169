import axios from "axios";

axios.interceptors.response.use(undefined, (error) => {
  if (
    error?.response?.status === 401 &&
    !error?.request?.responseURL?.endsWith("/login")
  ) {
    localStorage.loggedIn = "false";
    window.location.href = "/";
  }
  return Promise.reject(error);
});

export default axios;
