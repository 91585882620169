import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface DashboardState {
  email: string;
  name: string;
  loading: boolean;
  error: string;
}

export const initialState: DashboardState = {
  email: "",
  name: "",
  loading: true,
  error: "",
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setDashboardName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
      state.error = "";
    },
    setDashboardLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setDashboardError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetDashboardForm: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setDashboardEmail,
  setDashboardName,
  setDashboardLoading,
  setDashboardError,
  resetDashboardForm,
} = DashboardSlice.actions;

export const selectDashboard = (state: RootState) => state.dashboard;
export const selectDashboardLoading  =(state: RootState) => state.dashboard.loading;
export const selectDashboardError  =(state: RootState) => state.dashboard.error;
export const selectDashboardName  =(state: RootState) => state.dashboard.name;
export const selectDashboardEmail  =(state: RootState) => state.dashboard.email;

export default DashboardSlice.reducer;
