export enum AppType {
  Android = "Android",
  IOS = "Ios",
};
export type App = {
  id: string;
  shortId: string;
  name: string;
  date: Date;
  type: string;
  identifier: string;
  version: string;
  versionCode: string;
  size: number;
  appUrl: string;
  iconUrl: string;
  selected: boolean;
};
