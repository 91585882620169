import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AppType } from "../../common/types";


export interface UploadProgressState {
  controller: AbortController | null;
  name: string;
  size: number;
  progress: number;
  type: AppType;
  error: string;
}

export const initialState: UploadProgressState = {
  controller: null,
  name: "",
  size: 0,
  progress: 0,
  type: AppType.Android,
  error: '',
};

export const UploadProgressSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setUploadFile: (state, action: PayloadAction<UploadProgressState>) => {
      Object.assign(state, action.payload);
    },
    setController: (state, action: PayloadAction<AbortController | null>) => {
      state.controller = action.payload;
    },
    setUploadProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    setUploadError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { setUploadFile, setController, setUploadProgress, setUploadError } = UploadProgressSlice.actions;

export const selectUploadProgress = (state: RootState) => state.uploadProgress;

export default UploadProgressSlice.reducer;
