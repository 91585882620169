import { useEffect, useRef, useState } from "react";
import { DropIcon } from "../../common/icons/DropIcon";
import { UploadIcon } from "../../common/icons/UploadIcon";
import strings from "../../constant/strings";
import $ from "jquery";
import {
  setController,
  setUploadError,
  setUploadFile,
  setUploadProgress,
} from "../uploadProgress/UploadProgressSlice";
import { AppType } from "../../common/types";
import { useAppDispatch } from "../../app/hooks";
import { AppDispatch } from "../../app/store";
import { uploadApp } from "../../api/app/upload";
import { FRONTEND } from "../../constant";

export const FileUpload = () => {
  const [isDragged, setDragged] = useState(false);

  const uploadContainer = useRef<null | HTMLDivElement>(null);
  const fileContainer = useRef<null | HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (uploadContainer && uploadContainer.current) {
      const isAdvancedOptions =
        ("draggable" in uploadContainer.current ||
          ("ondragstart" in uploadContainer.current &&
            "ondrop" in uploadContainer.current)) &&
        "FormData" in window &&
        "FileReader" in window;
      if (isAdvancedOptions) {
        $(uploadContainer.current)
          .on(
            "drag dragstart dragend dragover dragenter dragleave drop",
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            }
          )
          .on("dragover dragenter", function () {
            setDragged(true);
          })
          .on("dragleave dragend drop", function () {
            setDragged(false);
          })
          .on("drop", function (e) {
            const files = e.originalEvent?.dataTransfer?.files;
            setFiles(files, dispatch);
          });
      }
    }
  }, [dispatch, uploadContainer]);

  return (
    <div className="container mt-5">
      <form>
        <div
          className={
            "border-5 border-info " + (isDragged ? "bg-info" : "bg-light")
          }
          style={{
            height: 150,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            cursor: "pointer",
            backgroundImage:
              "radial-gradient(circle, #ddd 1px, rgba(0,0,0,0) 1px)",
            backgroundSize: "1rem 1rem",
          }}
          ref={uploadContainer}
          onClick={() => {
            fileContainer?.current?.click();
          }}
        >
          {isDragged ? (
            <div className="text-center">
              <DropIcon />
              <br />
              {strings._drop}
            </div>
          ) : (
            <div className="text-center">
              <UploadIcon />
              <br />
              {strings._upload}
            </div>
          )}
        </div>
        <input
          type="file"
          className="d-none"
          name="file"
          ref={fileContainer}
          accept=".apk,.ipa"
          onChange={() => {
            const files = fileContainer?.current?.files;
            setFiles(files, dispatch);
          }}
        />
      </form>
      <ul></ul>
    </div>
  );
};

const setFiles = async (
  files: FileList | null | undefined,
  dispatch: AppDispatch
) => {
  if (!files || !files.length) {
    return;
  }
  const file = files[0];
  if (!(file.name.endsWith(".apk") || file.name.endsWith(".ipa"))) {
    return;
  }

  const abort = new AbortController();

  dispatch(
    setUploadFile({
      controller: abort,
      name: file.name,
      size: file.size,
      progress: 0,
      type: file.name.endsWith(".apk") ? AppType.Android : AppType.IOS,
      error: "",
    })
  );

  try {
    const res = await uploadApp(abort.signal, file, (progress) =>
      dispatch(setUploadProgress(progress))
    );
    if (!res || res.status !== 200) {
      dispatch(setUploadError(strings._uploadFailed));
    } else {
      setTimeout(() => {
        dispatch(setController(null));
        if (res.data?.shortId) {
          document.location.href = FRONTEND + "/" + res.data!!.shortId;
        }
      }, 500); 
    }
  } catch (err) {
    dispatch(setUploadError(strings._uploadFailed));
  }
};
