const strings = {
  _appName: "Beta",
  login: "Login",
  register: "Register",
  email: "Email",
  enterEmail: "Enter email",
  _emailHint: "We'll never share your email with anyone else",
  _emailError: "Please enter a valid email address",
  _emailEmpty: "Email address is required",
  password: "Password",
  enterPassword: "Enter password",
  _passwordError: "Password should be of atleast 8 characters",
  _passwordEmpty: "Password is required",
  name: "Name",
  date: "Date",
  enterName: "Enter name",
  _registrationSuccess: "Registration successful!",
  profile: "Profile",
  logout: "Logout",
  _unknownError: "An unknown error occurred",
  _upload: "Choose a file or drag it here",
  _drop: "Drop to upload",
  androidLogo: "Android logo",
  iosLogo: "IOS logo",
  uploading_: "Uploading...",
  _uploadFailed: "Error Uploading!",
  cancel: "Cancel",
  install: "Install",
  os: "OS",
  icon: "Icon",
  package_identifier: "Package/Identifier",
  version: "Version",
  size: "Size",
  versionCode: "Version Code",
  link: "Link",
  copy: "Copy",
};

export default strings;
