import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { App } from "../../common/types";

export interface ShortIdState {
  loading: boolean;
  app: App | undefined;
  error: string;
}

export const initialState: ShortIdState = {
  loading: true,
  app: undefined,
  error: "",
};

export const ShortIdSlice = createSlice({
  name: "shortId",
  initialState,
  reducers: {
    setShortIdLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShortIdError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setApp: (state, action: PayloadAction<App>) => {
      state.app = action.payload;
    },
  },
});

export const {
  setShortIdLoading,
  setShortIdError,
  setApp,
} = ShortIdSlice.actions;

export const selectShortId = (state: RootState) => state.shortId;

export default ShortIdSlice.reducer;
