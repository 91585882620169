import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Loader from "../../components/Loader/Loader";
import Spacer from "../../components/Spacer/Spacer";
import TextInput from "../../components/TextInput/TextInput";
import strings from "../../constant/strings";
import styles from "./AccountForm.module.css";
import {
  resetForm,
  selectForm,
  setAccountFormEmail,
  setAccountFormName,
  setAccountFormPassword,
  setAccountFormType,
} from "./AccountFormSlice";

export type AccountFormType = "login" | "register";

const AccountForm = ({
  type,
  onSubmit,
}: {
  type: AccountFormType;
  onSubmit?: () => void;
}) => {
  const form = useAppSelector(selectForm);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetForm());
    dispatch(setAccountFormType(type));
  }, [dispatch, type]);

  return (
    <div className={`card my-5 ${styles.content}`}>
      <div className="card-header">
        <h3 className="card-title">
          {type === "login" ? strings.login : strings.register}
        </h3>
      </div>
      <div className="card-body">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit?.();
          }}
          className="align-items-stretch"
        >
          <TextInput
            label={strings.email}
            placeholder={strings.enterEmail}
            type="email"
            value={form.email}
            valueSetter={(email) => dispatch(setAccountFormEmail(email))}
            error={form.emailEmpty ? strings._emailEmpty : form.emailError}
            required
          />
          {type === "register" && <Spacer />}
          {type === "register" && (
            <TextInput
              label={strings.name}
              placeholder={strings.enterName}
              value={form.name}
              valueSetter={(name) => dispatch(setAccountFormName(name))}
            ></TextInput>
          )}
          <Spacer />
          <TextInput
            label={strings.password}
            placeholder={strings.enterPassword}
            type="password"
            value={form.password}
            valueSetter={(password) => dispatch(setAccountFormPassword(password))}
            error={
              form.passwordEmpty ? strings._passwordEmpty : form.passwordError
            }
            required
          />
          <Spacer />
          {form.error ? (
            <>
              <div className="text-danger text-center">{form.error}</div>
              <Spacer />
            </>
          ) : null}
          <div className={styles.formButtonCenter}>
            {form.loading ? (
              <Loader />
            ) : (
              <button
                className={"block btn btn-primary text-capitalize"}
                disabled={
                  form.emailEmpty ||
                  form.passwordEmpty ||
                  form.emailError ||
                  form.passwordError
                    ? true
                    : false
                }
              >
                {type}
              </button>
            )}
          </div>
          {form.success ? (
            <div className="text-center">
              <span className="text-success">{strings._registrationSuccess}</span>{" "}
              <Link to="/">{strings.login}</Link>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default AccountForm;
