/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { getProfile } from "../../api/profile/get";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Loader from "../../components/Loader/Loader";
import strings from "../../constant/strings";
import AppList from "../appList/AppList";
import { setAppListTotalCount } from "../appList/AppListSlice";
import { FileUpload } from "../fileUpload/FileUpload";
import Header from "../header/Header";
import { UploadProgress } from "../uploadProgress/UploadProgress";
import {
  selectDashboardError,
  selectDashboardLoading,
  setDashboardEmail,
  setDashboardError,
  setDashboardLoading,
  setDashboardName,
} from "./DashboardSlice";

const Dashboard = () => {
  const loading = useAppSelector(selectDashboardLoading);
  const error = useAppSelector(selectDashboardError);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const abort = new AbortController();
    getProfile(abort.signal)
      .then((value) => {
        if (!value.data || !value.data.email) {
          return;
        }
        dispatch(setDashboardEmail(value.data.email));
        dispatch(setDashboardName(value.data.name ? value.data.name : ""));
        dispatch(setDashboardError(""));
        dispatch(setAppListTotalCount(value.data.appCount || 0));
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(setDashboardError(error.response.data.message));
        } else {
          dispatch(setDashboardError(strings._unknownError));
        }
      })
      .finally(() => {
        dispatch(setDashboardLoading(false));
      });
    return () => {
      abort.abort();
    };
  }, [dispatch]);
  return loading ? (
    <div className="text-center mt-3">
      <Loader />
    </div>
  ) : error ? (
    <div>{error}</div>
  ) : (
    <div>
      <Header>
        <FileUpload />
        <UploadProgress />
        <AppList />
      </Header>
    </div>
  );
};

export default Dashboard;
