import { useSelector } from "react-redux";
import Navbar, { NavItem } from "../../components/Navbar/Navbar";
import strings from "../../constant/strings";
import { selectFormType } from "../accountForm/AccountFormSlice";

const Index = ({ children }: { children: JSX.Element }) => {
  const formType = useSelector(selectFormType);

  const items: NavItem[] = [
    {
      key: 0,
      name: strings.login,
      href: "/",
      active: formType === "login" ? true : false,
    },
    {
      key: 1,
      name: strings.register,
      href: "/register",
      active: formType === "register" ? true : false,
    },
  ];

  return (
    <div>
      <Navbar items={items} />
      {children}
    </div>
  );
};

export default Index;
