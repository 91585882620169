import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import accountFormReducer from '../features/accountForm/AccountFormSlice';
import dashboardReducer from '../features/dashboard/DashboardSlice';
import headerReducer from '../features/header/HeaderSlice';
import uploadProgressReducer from '../features/uploadProgress/UploadProgressSlice';
import shortIdReducer from '../features/shortId/ShortIdSlice';
import appListReducer from '../features/appList/AppListSlice';

export const store = configureStore({
  reducer: {
    accountForm: accountFormReducer,
    dashboard: dashboardReducer,
    header: headerReducer,
    uploadProgress: uploadProgressReducer,
    shortId: shortIdReducer,
    appList: appListReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
