import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppType } from "../../common/types";
import strings from "../../constant/strings";
import { selectUploadProgress, setController } from "./UploadProgressSlice";

export const UploadProgress = () => {
  const uploadProgress = useAppSelector(selectUploadProgress);
  const width = Math.round(
    (uploadProgress.progress * 100) / uploadProgress.size
  );
  const dispatch = useAppDispatch();
  return (
    <div>
      {!uploadProgress.controller ? null : (
        <div className="position-fixed d-flex align-items-center justify-content-center h-100 w-100 top-0 left-0 bg-light overflow-auto">
          <div
            style={{ width: "300px", maxWidth: "100%" }}
            className="text-center mx-auto card"
            role="alertdialog"
          >
            <div className="card-header">
              <h3 className="card-title text-start">{strings.uploading_}</h3>
            </div>
            <div className="card-body">
              <div style={{ maxWidth: "150px" }} className="mx-auto">
                <img
                  src={
                    uploadProgress.type === AppType.Android
                      ? "/android.svg"
                      : "/ios.svg"
                  }
                  alt={
                    uploadProgress.type === AppType.Android
                      ? strings.androidLogo
                      : strings.iosLogo
                  }
                />
              </div>
              <div className="progress mt-3">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{
                    width: width + "%",
                  }}
                  aria-valuenow={width}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <div className="float-end">{width}%</div>
              <div className="float-start">{uploadProgress.name}</div>
              <div className="clearfix" />
              {uploadProgress.error ? (
                <div className="text-danger mt-2">{uploadProgress.error}</div>
              ) : (
                <div className="text-end">
                  <button className="btn btn-sm btn-danger mt-2" onClick={() => {
                    uploadProgress.controller?.abort();
                    dispatch(setController(null));
                  }}>
                    {strings.cancel} &times;
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
