import authAxios from "../../common/authAxios";
import { DOMAIN } from "../../constant";

export const uploadApp = (
  signal: AbortSignal,
  file: File,
  onProgress: (progress: number) => void
) => {
  const formData = new FormData();
  formData.append("file", file);
  return authAxios.post(`${DOMAIN}/app/upload`, formData, {
    signal,
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (e) => {
      onProgress(e.loaded);
    },
  });
};
