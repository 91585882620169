import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import strings from "../../constant/strings";
import { AccountFormType } from "./AccountForm";

export interface AccountFormState {
  email: string;
  emailError: string;
  emailEmpty: boolean;
  name: string;
  password: string;
  passwordError: string;
  passwordEmpty: boolean;
  type: AccountFormType;
  loading: boolean;
  error: string;
  success: boolean;
}

export const initialState: AccountFormState = {
  email: "",
  emailError: "",
  emailEmpty: false,
  name: "",
  password: "",
  passwordError: "",
  passwordEmpty: false,
  type: "register",
  loading: false,
  error: "",
  success: false,
};

export const AccountFormSlice = createSlice({
  name: "accountForm",
  initialState,
  reducers: {
    setAccountFormEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
      if (action.payload.trim()) {
        state.emailEmpty = false;
      } else {
        state.emailEmpty = true;
      }
      state.error = "";
    },
    setAccountFormEmailError: (state, action: PayloadAction<string>) => {
      state.emailError = action.payload;
    },
    setAccountFormEmailEmpty: (state, action: PayloadAction<boolean>) => {
      state.emailEmpty = action.payload;
    },
    setAccountFormName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
      state.error = "";
    },
    setAccountFormPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
      if (action.payload) {
        state.passwordEmpty = false;
      } else {
        state.passwordEmpty = true;
      }
      state.passwordError =
        action.payload.length < 8 ? strings._passwordError : "";
      state.error = "";
    },
    setAccountFormPasswordError: (state, action: PayloadAction<string>) => {
      state.passwordError = action.payload;
    },
    setAccountFormPasswordEmpty: (state, action: PayloadAction<boolean>) => {
      state.passwordEmpty = action.payload;
    },
    setAccountFormType: (state, action: PayloadAction<AccountFormType>) => {
      state.type = action.payload;
    },
    setAccountFormLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAccountFormError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      Object.assign(state, initialState);
    },
    setAccountFormSuccess: (state) => {
      state.success = true;
    },
  },
});

export const {
  setAccountFormEmail,
  setAccountFormEmailError,
  setAccountFormEmailEmpty,
  setAccountFormName,
  setAccountFormPassword,
  setAccountFormPasswordError,
  setAccountFormPasswordEmpty,
  setAccountFormType,
  setAccountFormLoading,
  setAccountFormError,
  resetForm,
  setAccountFormSuccess,
} = AccountFormSlice.actions;

export const selectForm = (state: RootState) => state.accountForm;
export const selectFormType = (state: RootState) => state.accountForm.type;

export default AccountFormSlice.reducer;
