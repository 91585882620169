import axios from "axios";
import { DOMAIN } from "../../constant";

export const register = (email: string, name: string, password: string) => {
  return axios.post(`${DOMAIN}/account/register`, {
    email,
    name,
    password,
  });
};
