import Dashboard from "./features/dashboard/Dashboard";
import Login from "./features/login/Login";

const App = () => {
  return (
    localStorage.loggedIn === "true" ? <Dashboard /> : <Login />
  );
}

export default App;
